import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Card, Layout, Input, Button } from "antd";
import { observer } from "mobx-react-lite";
import { Typography, Table } from "antd";
import { useUserStore } from "@/providers/BaseStoresProvider";
import BN from "@/utils/BN";
import { cutString } from "@/utils/strings";

const { Title, Text } = Typography;
const { Search } = Input;

export const ReferralsPage = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [rowsLoading, setRowLoading] = useState<string[]>([]);
  const [searchVal, setSearch] = useState<string>("");
  const [searchValId, setSearchId] = useState<string>("");
  const userStore = useUserStore();

  const loadRequests = useCallback(async () => {
    setLoading(true);
    await userStore.loadRefferals();
    setLoading(false);
  }, []);

  useEffect(() => {
    loadRequests();
  }, [loadRequests]);

  const onSearch = async (value: ChangeEvent<HTMLInputElement>) => {
    setSearch(value.currentTarget.value);
  };

  const onSearchId = async (value: ChangeEvent<HTMLInputElement>) => {
    setSearchId(value.currentTarget.value);
  };

  const onSearchSubmit = async () => {
    setLoading(true);
    userStore.updateVipRef(searchValId, searchVal);
    setLoading(false);
  };

  const columns: any = useMemo(
    () => [
      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        render: (val: string) => (
          <Text
            copyable={{
              text: val ?? ""
            }}
          >
            {cutString(val, 4, 5)}
          </Text>
        )
      },
      {
        title: "Promocode",
        dataIndex: "uniquePromo",
        key: "uniquePromo"
      },
      {
        title: "Total Revenue",
        dataIndex: "totalRevenue",
        key: "totalRevenue",
        render: (val: number) => val + " $",
        sorter: (a: any, b: any) => a.totalRevenue - b.totalRevenue
      },
      {
        title: "Last Month Revenue",
        dataIndex: "lastEpochRev",
        key: "lastEpochRev",
        render: (val: number) => val + " $"
      },
      {
        title: "Rev Share",
        dataIndex: "revenueShare",
        key: "revenueShare",
        render: (val: number) => val + " %",
        sorter: (a: any, b: any) => a.revenueShare - b.revenueShare
      },
      {
        title: "VIP Share",
        dataIndex: "vipRefShare",
        key: "vipRefShare",
        render: (val: number) => val + " %",
        sorter: (a: any, b: any) => a.vipRefShare - b.vipRefShare
      },
      {
        title: "Referrals amount",
        dataIndex: "refsList",
        key: "refsList",
        defaultSortOrder: "descend",
        render: (val: string[]) => val?.length,
        sorter: (a: any, b: any) => a?.refsList.length - b?.refsList.length
      }
    ],
    [userStore]
  );

  const columnsDetailed: any = useMemo(
    () => [
      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId"
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Revenue",
        dataIndex: "rev",
        key: "rev",
        render: (val: any[], full: any) => {
          return (
            new BN(full.totalDeposits).minus(full.totalWithdraw).toFixed(2) +
            " $"
          );
        },
        sorter: (a: any, b: any) => {
          const totalA = Number(a.totalDeposits) - Number(a.totalWithdraw);
          // await new Promise((res) => setTimeout(res, 10));
          const totalB = Number(b.totalDeposits) - Number(b.totalWithdraw);

          return Number(totalA) - Number(totalB);
        }
      },
      {
        title: "Deposits",
        dataIndex: "totalDeposits",
        key: "totalDeposits",
        render: (val: any[]) => {
          return val + " $";
        },
        sorter: (a: any, b: any) =>
          Number(a.totalDeposits) - Number(b.totalDeposits)
      },
      {
        title: "Withdraw",
        dataIndex: "totalWithdraw",
        key: "totalWithdraw",
        render: (val: any[]) => {
          return val + " $";
        },
        sorter: (a: any, b: any) =>
          Number(a.totalWithdraw) - Number(b.totalWithdraw)
      }
    ],
    [userStore]
  );

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        <Typography>
          <Title>Referrals List</Title>
        </Typography>

        <Table
          dataSource={userStore.referralsList}
          columns={columns}
          loading={isLoading}
          scroll={{ x: true }}
          rowKey={(record) => record.userId}
          expandable={{
            onExpand: async (isOpen, record) => {
              if (isOpen) {
                setRowLoading((s) => [...s, record.userId.toString() ?? ""]);
                await userStore.loadRefferalsDetailed(record.userId);

                setRowLoading((s) => {
                  const index = s.indexOf(record.userId.toString() ?? "");
                  s.splice(index, 1);
                  return [...s];
                });
              }
            },
            expandedRowRender: (record) => {
              const userReferrals = userStore.referralsListDetailed.find(
                (_) => {
                  const key = Object.keys(_);
                  if (key[0] === record.userId) return _;
                }
              );

              if (!userReferrals) return <div>No referrals</div>;

              if (rowsLoading.includes(record.userId)) {
                return (
                  <Card
                    style={{ maxWidth: "40%", margin: "0 auto" }}
                    type="inner"
                    bordered
                    title={`Referrals for ${record.userId}`}
                  >
                    Loading...
                  </Card>
                );
              }

              console.log(userReferrals, "__USERREFS");

              return (
                <Table
                  className="table-inner"
                  dataSource={Object.values(userReferrals)[0]}
                  columns={columnsDetailed}
                  scroll={{ x: true }}
                  loading={isLoading}
                  rowKey={(record) => record.userId}
                />
              );
            },
            rowExpandable: (record) => record.refsList?.length !== 0
          }}
        />

        <Typography>
          <Title>VIP REFS</Title>
          <Card
            style={{ margin: "20px 0 40px 0" }}
            type="inner"
            title="Set VIP ref share"
          >
            <Search
              placeholder="VIP percent"
              size="large"
              onChange={onSearch}
              value={searchVal}
              style={{ marginBottom: "10px" }}
            />
            <Search
              placeholder="VIP id"
              size="large"
              onChange={onSearchId}
              value={searchValId}
              style={{ marginBottom: "20px" }}
            />
            <Button
              onClick={onSearchSubmit}
              type="primary"
              style={{ width: "100%" }}
            >
              SUBMIT
            </Button>
          </Card>
        </Typography>
      </Layout.Content>
    </Layout>
  );
});
