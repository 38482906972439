/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProfileUpdateScheme {
  /** @example "name" */
  email?: string;
  /** @example "" */
  password?: string;
  /** @example "2" */
  phone?: string;
  /** @example "10" */
  username?: string;
  /** @example "description" */
  description?: string;
  /** @example false */
  showUsername?: boolean;
  /** @example false */
  showUserData?: boolean;
}

export interface QueryBasic {
  /** @example "2" */
  page: string;
  /** @example "10" */
  limit: string;
}

export interface QueryAdminUsers {
  /** @example "name" */
  sort?: string;
  /** @example "" */
  fields?: string;
  /** @example "10" */
  search?: string;
  /** @example "0" */
  totalWagered?: string;
  /** @example "0" */
  totalDeposits?: string;
  /** @example "0" */
  totalWithdraw?: string;
  /** @example "1" */
  id?: string;
}

export interface Query {
  /** @example "name" */
  sort?: string;
  /** @example "" */
  fields?: string;
  /** @example "10" */
  search?: string;
  /** @example "2" */
  page: string;
  /** @example "10" */
  limit: string;
  /** @example "1" */
  id?: string;
}

export interface RefsModelDef {
  /** @example "1" */
  totalRevenue: string;
  /** @example ["1"] */
  refsList: string[];
  /** @example "1" */
  refMain: string;
  /** @example "1" */
  toClaimUsd: string;
  /** @example 0 */
  vipRefShare: number;
}

export interface VipModelDef {
  /** @example "1" */
  vipLevel: string;
  /** @example "1" */
  totalXp: string;
  /** @example 0 */
  totalWins: number;
  /** @example 0 */
  totalBets: number;
  /** @example "1" */
  betInUsd: string;
  /** @example "1" */
  nextLevelXp: string;
  /** @example "prmo" */
  promoCode: string;
  /** @example 1 */
  freeSpins: number;
  /** @example ["qwe"] */
  achievements: string[];
  /** @example ["qwe"] */
  confirmedPromocodes: string[];
  /** @example ["0"] */
  rewardBoxList: string[];
  dailyQuest: {
    /** @example "Wager only" */
    name?: string;
    /** @example "Wager only" */
    description?: string;
    /** @example 1725638400 */
    expireAt?: number;
    /** @example "700" */
    needToWager?: string;
    /** @example "0" */
    wageredAmount?: string;
    /** @example false */
    prizeClaimed?: boolean;
    /** @example 0.2 */
    claimPrize?: number;
  }[];
  weeklyQuest: {
    /** @example "Wager only" */
    name?: string;
    /** @example "Wager only" */
    description?: string;
    /** @example 1725638400 */
    expireAt?: number;
    /** @example "700" */
    needToWager?: string;
    /** @example "0" */
    wageredAmount?: string;
    /** @example false */
    prizeClaimed?: boolean;
    /** @example 0.2 */
    claimPrize?: number;
  }[];
}

export interface Web3Balance {
  balances: {
    /** @example " _.symbol" */
    symbol: string;
    /** @example 0 */
    sort: number;
    /** @example " _.alias" */
    alias: string;
    /** @example "_.lastLogId" */
    lastLogId: string;
    /** @example "_.amount" */
    amount: string;
    /** @example "_.amount" */
    amountInDollar: string;
    /** @example false */
    supportCrypto: boolean;
  }[];
  suppTokens: {
    number: {
      /** @example "BUSD" */
      alias?: string;
      /** @example "BUSD" */
      symbol?: string;
      /** @example 6 */
      decimals?: number;
      /** @example "0x4Fabb145d64652a948d72533023f6E7A623C7C53" */
      contract?: string;
      /** @example 1 */
      sort?: number;
    };
  }[];
}

export interface ByEmailScheme {
  /** @example "0x" */
  email?: string;
  /** @example "EVM" */
  password?: string;
  /** @example "qwe" */
  referral?: string;
  /** @example "promoc" */
  promoCode?: string;
}

export interface VipRefUpdate {
  /** @example 0 */
  amount?: number;
  /** @example "id" */
  id?: string;
}

export interface SlotsList {
  /** @example 0 */
  page?: number;
  /** @example "USDT" */
  provider?: string;
  /** @example "qQq" */
  name?: string;
  /** @example false */
  isFreespin?: boolean;
}

export interface BalanceSlot {
  /** @example "Sign" */
  player_id?: string;
  /** @example "USDT" */
  currency?: string;
}

export interface InitSlot {
  /** @example "Sign msg" */
  game_uuid?: string;
  /** @example "USDT" */
  curr?: string;
}

export interface Web3UserParams {
  /** @example "Sign msg" */
  signMessage?: string;
  /** @example "HEX" */
  signHex?: string;
  /** @example "PostTest" */
  walletSign?: string;
  /** @example "PostTest" */
  walletName?: string;
  /** @example "Post genre" */
  walletAddress?: string;
  /** @example "6bqqq" */
  referral?: string;
  /** @example "Post genre" */
  nonce?: string;
  /** @example "promoc" */
  promoCode?: string;
}

export interface Web3User {
  /** @example "PostTest" */
  walletSign?: string;
  /** @example "PostTest" */
  walletName?: string;
  /** @example "Post genre" */
  walletAddress?: string;
  /** @example false */
  google2StepAuth?: boolean;
  /** @example "PostTest" */
  name?: string;
  /** @example false */
  passExist?: boolean;
  /** @example "PostTest" */
  email?: string;
  /** @example false */
  emailVerified?: boolean;
  /** @example 0 */
  createdAtUnix?: number;
  /** @example 0 */
  updatedAtUnix?: number;
  /** @example "user" */
  role?: string;
}

export interface GetWeb3User {
  /** @example false */
  google2StepAuth?: boolean;
  /** @example "PostTest" */
  name?: string;
  /** @example false */
  passExist?: boolean;
  /** @example "PostTest" */
  email?: string;
  /** @example false */
  emailVerified?: boolean;
  /** @example 0 */
  createdAtUnix?: number;
  /** @example 0 */
  updatedAtUnix?: number;
  /** @example "user" */
  role?: string;
}

export interface Post {
  /** @example "PostTest" */
  postTitle?: string;
  /** @example "PostTest" */
  postBody?: string;
  /** @example "Post genre" */
  genre?: string;
  user?: Web3User;
  /** @example 0 */
  duration?: number;
}

export interface Comment {
  /** @example "CommentTest" */
  commentText?: string;
  /** @example "id" */
  post?: string;
  user?: Web3User;
}

export interface ApiResponse {
  /** @example 400 */
  code?: number;
  /** @example "Test" */
  msg?: string;
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain"
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "http://localhost:3000"
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === "object"
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== "string"
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { "Content-Type": type }
          : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    });
  };
}

/**
 * @title My API
 * @version 1.0.0
 * @baseUrl http://localhost:3000
 *
 * Description
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  swaggerJson = {
    /**
     * No description
     *
     * @name SwaggerJson
     * @request GET:/swagger.json
     */
    swaggerJson: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/swagger.json`,
        method: "GET",
        ...params
      })
  };
  ready = {
    /**
     * No description
     *
     * @name Ready
     * @request GET:/ready
     */
    ready: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/ready`,
        method: "GET",
        ...params
      })
  };
  health = {
    /**
     * No description
     *
     * @name Health
     * @request GET:/health
     */
    health: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/health`,
        method: "GET",
        ...params
      })
  };
  user = {
    /**
     * No description
     *
     * @name Register
     * @request POST:/user/register
     */
    register: (body: ByEmailScheme, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/register`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name Update
     * @request POST:/user/update
     */
    update: (body: ProfileUpdateScheme, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name Login
     * @request POST:/user/login
     */
    login: (
      body: {
        /** @example "0x" */
        email?: string;
        /** @example "EVM" */
        password?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/user/login`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name RegisterWeb3
     * @request POST:/user/register/web3
     */
    registerWeb3: (body: Web3UserParams, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/register/web3`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name Logout
     * @request POST:/user/logout
     */
    logout: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/logout`,
        method: "POST",
        ...params
      }),

    /**
     * No description
     *
     * @name Withdraw
     * @request POST:/user/withdraw
     */
    withdraw: (
      body: {
        /** @example "usdt" */
        alias?: string;
        /** @example "1" */
        amount?: string;
        /** @example "0x" */
        address?: string;
        /** @example "1" */
        chain?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/user/withdraw`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name UpdateBalance
     * @request POST:/user/update/balance
     */
    updateBalance: (
      body: {
        /** @example 1 */
        blocks?: number;
        /** @example "1" */
        chain: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/user/update/balance`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name Chain
     * @request POST:/user/chain
     */
    chain: (
      body: {
        /** @example "1" */
        chain?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "0x" */
          address?: string;
        },
        void
      >({
        path: `/user/chain`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name Promocode
     * @request POST:/user/promocode
     */
    promocode: (
      body: {
        /** @example "qqq" */
        promocode?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/promocode`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name ReferralUpdate
     * @request POST:/user/referral/update
     */
    referralUpdate: (
      body: {
        /** @example "qqq" */
        refMain?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/referral/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name SwapTokens
     * @request POST:/user/swap/tokens
     */
    swapTokens: (
      body: {
        /** @example "usdt" */
        fromAlias?: string;
        /** @example "usdt" */
        toAlias?: string;
        /** @example 0 */
        fromAmount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/swap/tokens`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name ClaimVipBox
     * @request POST:/user/claim/vip/box
     */
    claimVipBox: (
      body: {
        /** @example "0" */
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/claim/vip/box`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name LiveBets
     * @request GET:/user/liveBets
     */
    liveBets: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/liveBets`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name FreespinPrizes
     * @request GET:/user/freespin/prizes
     */
    freespinPrizes: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/freespin/prizes`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name FreespinPrizesUser
     * @request GET:/user/freespin/prizes/user
     */
    freespinPrizesUser: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/freespin/prizes/user`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name DailyBonux
     * @request GET:/user/daily/bonux
     */
    dailyBonux: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/daily/bonux`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name FreespinResult
     * @request GET:/user/freespin/result
     */
    freespinResult: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/freespin/result`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name QuestsClaim
     * @request GET:/user/quests/claim
     */
    questsClaim: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/quests/claim`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Achievements
     * @request GET:/user/achievements
     */
    achievements: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/achievements`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name ClaimBonus
     * @request GET:/user/claim/bonus
     */
    claimBonus: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/claim/bonus`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AllTxs
     * @request GET:/user/all/txs
     */
    allTxs: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/all/txs`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Check
     * @request GET:/user/check
     */
    check: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/check`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name CheckAdmin
     * @request GET:/user/check/admin
     */
    checkAdmin: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/check/admin`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Balances
     * @request GET:/user/balances
     */
    balances: (params: RequestParams = {}) =>
      this.request<Web3Balance, void>({
        path: `/user/balances`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Prices
     * @request GET:/user/prices
     */
    prices: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/prices`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Vipdata
     * @request GET:/user/vipdata
     */
    vipdata: (params: RequestParams = {}) =>
      this.request<VipModelDef, void>({
        path: `/user/vipdata`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name RefsData
     * @request GET:/user/refsData
     */
    refsData: (params: RequestParams = {}) =>
      this.request<RefsModelDef, void>({
        path: `/user/refsData`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name RefDetailed
     * @request GET:/user/refDetailed/{id}
     */
    refDetailed: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/refDetailed/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name UserLogindata
     * @request GET:/user/user/logindata/{id}
     */
    userLogindata: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/user/logindata/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name LastUserGames
     * @request GET:/user/last/userGames/{id}
     */
    lastUserGames: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/last/userGames/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Swaps
     * @request GET:/user/swaps/{id}
     */
    swaps: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/swaps/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name WithdrawApprove
     * @request POST:/user/withdraw/approve
     */
    withdrawApprove: (
      body: {
        /** @example "usdt" */
        alias?: string;
        /** @example "1" */
        amount?: string;
        /** @example "0x" */
        address?: string;
        /** @example 0 */
        txNonce?: number;
        /** @example 0 */
        status?: number;
        /** @example "1" */
        chain?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/user/withdraw/approve`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name AdminLogin
     * @request POST:/user/admin/login
     */
    adminLogin: (body: Web3UserParams, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/admin/login`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name AdminVipUpdate
     * @request POST:/user/admin/vip/update
     */
    adminVipUpdate: (body: VipRefUpdate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/vip/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name AllWallets
     * @request POST:/user/all/wallets
     */
    allWallets: (
      body: {
        /** @example "qweqwe" */
        user?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/user/all/wallets`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name AdminCommentDelete
     * @request POST:/user/admin/comment/delete
     */
    adminCommentDelete: (
      body: {
        /** @example "o" */
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/admin/comment/delete`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name AdminCommentCreate
     * @request POST:/user/admin/comment/create
     */
    adminCommentCreate: (
      body: {
        /** @example "o" */
        name?: string;
        /** @example 0 */
        createdAt?: number;
        /** @example "o" */
        id?: string;
        /** @example "o" */
        game?: string;
        /** @example "o" */
        text?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/admin/comment/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name AdminBanksReset
     * @request POST:/user/admin/banks/reset
     */
    adminBanksReset: (
      body: {
        /** @example "qweqwe" */
        bankId?: string;
        /** @example "qweqwe" */
        bankSymbol?: string;
        /** @example false */
        isCrash?: boolean;
        /** @example false */
        resetAll?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/admin/banks/reset`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name AdminSwaps
     * @request GET:/user/admin/swaps
     */
    adminSwaps: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/swaps`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminUserGames
     * @request GET:/user/admin/userGames/{id}
     */
    adminUserGames: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/userGames/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminSlots
     * @request GET:/user/admin/slots/{id}
     */
    adminSlots: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/slots/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminSlotsList
     * @request GET:/user/admin/slotsList
     */
    adminSlotsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/slotsList`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminReferrals
     * @request GET:/user/admin/referrals
     */
    adminReferrals: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/referrals`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminLotteryUser
     * @request GET:/user/admin/lotteryUser/{id}
     */
    adminLotteryUser: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/admin/lotteryUser/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminUsersList
     * @request GET:/user/admin/usersList
     */
    adminUsersList: (
      query?: {
        page?: string;
        limit?: string;
        totalDeposits?: string;
        totalWithdraw?: string;
        totalWagered?: string;
        /** Create query */
        params?: QueryAdminUsers;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/user/admin/usersList`,
        method: "GET",
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @name AdminUser
     * @request GET:/user/admin/user/{id}
     */
    adminUser: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/user/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminRelogin
     * @request GET:/user/admin/relogin/{id}
     */
    adminRelogin: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/relogin/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name WithdrawList
     * @request GET:/user/withdraw/list
     */
    withdrawList: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/user/withdraw/list`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminBanksLoad
     * @request GET:/user/admin/banks/load
     */
    adminBanksLoad: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/banks/load`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminLotteryall
     * @request GET:/user/admin/lotteryall
     */
    adminLotteryall: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/lotteryall`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminRandomizeslotsLikes
     * @request GET:/user/admin/randomizeslots/likes
     */
    adminRandomizeslotsLikes: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/randomizeslots/likes`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminFiatDisable
     * @request GET:/user/admin/fiat/disable/{id}
     */
    adminFiatDisable: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/fiat/disable/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminDisable
     * @request GET:/user/admin/disable/{id}
     */
    adminDisable: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/disable/${id}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminDelete
     * @request GET:/user/admin/delete/{id}
     */
    adminDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/admin/delete/${id}`,
        method: "GET",
        ...params
      })
  };
  comments = {
    /**
     * No description
     *
     * @name CreateComment
     * @request POST:/comments/createComment
     */
    createComment: (
      body: {
        /** @example "1" */
        text?: string;
        /** @example "crash" */
        game?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/comments/createComment`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name LikeComment
     * @request POST:/comments/likeComment
     */
    likeComment: (
      body: {
        /** @example "0" */
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/comments/likeComment`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name GetComments
     * @request POST:/comments/getComments
     */
    getComments: (
      body: {
        /** @example "crash" */
        game?: string;
        /** @example "id" */
        user?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/comments/getComments`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      })
  };
  emails = {
    /**
     * No description
     *
     * @name VerifyLink
     * @request GET:/emails/verify/link/{id}/{token}
     */
    verifyLink: (id: string, token: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/emails/verify/link/${id}/${token}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name VerifySend
     * @request GET:/emails/verify/send
     */
    verifySend: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/emails/verify/send`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name VerifyEmailcode
     * @request POST:/emails/verify/emailcode
     */
    verifyEmailcode: (
      body: {
        /** @example false */
        isVerifyEmail?: boolean;
        /** @example 123 */
        userCode?: number;
        /** @example "q" */
        email?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/emails/verify/emailcode`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name VerifyPromo
     * @request POST:/emails/verify/promo
     */
    verifyPromo: (
      body: {
        /** @example "qweqwe" */
        userId?: string;
        /** @example "qweqwe" */
        token?: string;
        /** @example 0 */
        promoId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/emails/verify/promo`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      })
  };
  games = {
    /**
     * No description
     *
     * @name UserHistory
     * @request GET:/games/user/history
     */
    userHistory: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/games/user/history`,
        method: "GET",
        ...params
      })
  };
  lottery = {
    /**
     * No description
     *
     * @name UserTickets
     * @request POST:/lottery/user/tickets
     */
    userTickets: (
      query: {
        /** Create query */
        params: Query;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/lottery/user/tickets`,
        method: "POST",
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @name History
     * @request POST:/lottery/history
     */
    history: (
      query: {
        /** Create query */
        params: Query;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/lottery/history`,
        method: "POST",
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @name List
     * @request GET:/lottery/list
     */
    list: (
      query: {
        /** Create query */
        params: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/lottery/list`,
        method: "GET",
        query: query,
        ...params
      })
  };
  limbo = {
    /**
     * No description
     *
     * @name Updateseeds
     * @request POST:/limbo/updateseeds
     */
    updateseeds: (
      body: {
        /** @example 0 */
        nonce?: number;
        /** @example "qwe123" */
        clientSeed?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/limbo/updateseeds`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name Data
     * @request GET:/limbo/data
     */
    data: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/limbo/data`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Checkseeds
     * @request GET:/limbo/checkseeds
     */
    checkseeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/limbo/checkseeds`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Seeds
     * @request GET:/limbo/seeds
     */
    seeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/limbo/seeds`,
        method: "GET",
        ...params
      })
  };
  hashdice = {
    /**
     * No description
     *
     * @name Updateseeds
     * @request POST:/hashdice/updateseeds
     */
    updateseeds: (
      body: {
        /** @example 0 */
        nonce?: number;
        /** @example "qwe123" */
        clientSeed?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/hashdice/updateseeds`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name Data
     * @request GET:/hashdice/data
     */
    data: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hashdice/data`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Checkseeds
     * @request GET:/hashdice/checkseeds
     */
    checkseeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hashdice/checkseeds`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Seeds
     * @request GET:/hashdice/seeds
     */
    seeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hashdice/seeds`,
        method: "GET",
        ...params
      })
  };
  plinko = {
    /**
     * No description
     *
     * @name Updateseeds
     * @request POST:/plinko/updateseeds
     */
    updateseeds: (
      body: {
        /** @example 0 */
        nonce?: number;
        /** @example "qwe123" */
        clientSeed?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/plinko/updateseeds`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name Data
     * @request GET:/plinko/data
     */
    data: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/plinko/data`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Checkseeds
     * @request GET:/plinko/checkseeds
     */
    checkseeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/plinko/checkseeds`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Seeds
     * @request GET:/plinko/seeds
     */
    seeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/plinko/seeds`,
        method: "GET",
        ...params
      })
  };
  coinflip = {
    /**
     * No description
     *
     * @name Updateseeds
     * @request POST:/coinflip/updateseeds
     */
    updateseeds: (
      body: {
        /** @example 0 */
        nonce?: number;
        /** @example "qwe123" */
        clientSeed?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/coinflip/updateseeds`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name Data
     * @request GET:/coinflip/data
     */
    data: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/coinflip/data`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Checkseeds
     * @request GET:/coinflip/checkseeds
     */
    checkseeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/coinflip/checkseeds`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Seeds
     * @request GET:/coinflip/seeds
     */
    seeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/coinflip/seeds`,
        method: "GET",
        ...params
      })
  };
  classdice = {
    /**
     * No description
     *
     * @name Updateseeds
     * @request POST:/classdice/updateseeds
     */
    updateseeds: (
      body: {
        /** @example 0 */
        nonce?: number;
        /** @example "qwe123" */
        clientSeed?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/classdice/updateseeds`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name Data
     * @request GET:/classdice/data
     */
    data: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/classdice/data`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Checkseeds
     * @request GET:/classdice/checkseeds
     */
    checkseeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/classdice/checkseeds`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Seeds
     * @request GET:/classdice/seeds
     */
    seeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/classdice/seeds`,
        method: "GET",
        ...params
      })
  };
  mines = {
    /**
     * No description
     *
     * @name Updateseeds
     * @request POST:/mines/updateseeds
     */
    updateseeds: (
      body: {
        /** @example 0 */
        nonce?: number;
        /** @example "qwe123" */
        clientSeed?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/mines/updateseeds`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name Data
     * @request GET:/mines/data
     */
    data: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/mines/data`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Checkseeds
     * @request GET:/mines/checkseeds
     */
    checkseeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/mines/checkseeds`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Seeds
     * @request GET:/mines/seeds
     */
    seeds: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/mines/seeds`,
        method: "GET",
        ...params
      })
  };
  fiat = {
    /**
     * No description
     *
     * @name Currencies
     * @request GET:/fiat/currencies
     */
    currencies: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/fiat/currencies`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name UserList
     * @request GET:/fiat/user/list
     */
    userList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/fiat/user/list`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name UserTxs
     * @request GET:/fiat/user/txs
     */
    userTxs: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/fiat/user/txs`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name InvoiceEuphoria
     * @request POST:/fiat/invoice/euphoria
     */
    invoiceEuphoria: (
      body: {
        /** @example "0" */
        amount?: string;
        /** @example 0 */
        currencyId?: number;
        /** @example "0" */
        currencyName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/invoice/euphoria`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name InvoiceRepay
     * @request POST:/fiat/invoice/repay
     */
    invoiceRepay: (
      body: {
        /** @example "0" */
        amount?: string;
        /** @example 0 */
        currencyId?: number;
        /** @example "0" */
        currencyName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/invoice/repay`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name AppealEuphoria
     * @request POST:/fiat/appeal/euphoria
     */
    appealEuphoria: (
      body: {
        /** @example "0" */
        amount?: string;
        /** @example "0" */
        imgBase?: string;
        /** @example "0" */
        txId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/appeal/euphoria`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name InvoicePelerin
     * @request POST:/fiat/invoice/pelerin
     */
    invoicePelerin: (
      body: {
        /** @example "0" */
        amount?: string;
        /** @example 0 */
        currencyId?: number;
        /** @example "0" */
        currencyName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/invoice/pelerin`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name InvoiceEuphoriaWithdraw
     * @request POST:/fiat/invoice/euphoria/withdraw
     */
    invoiceEuphoriaWithdraw: (
      body: {
        /** @example "0" */
        amount?: string;
        /** @example 0 */
        currencyId?: number;
        /** @example "0" */
        currencyName?: string;
        /** @example "0" */
        cardNumber?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/invoice/euphoria/withdraw`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name SubmitEuphoria
     * @request POST:/fiat/submit/euphoria
     */
    submitEuphoria: (
      body: {
        /** @example "0" */
        amount?: string;
        /** @example 0 */
        currencyId?: number;
        /** @example "0" */
        currencyName?: string;
        /** @example "0" */
        cardNumber?: string;
        /** @example "0" */
        txId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/submit/euphoria`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name CbWith
     * @request POST:/fiat/cb/with
     */
    cbWith: (
      body: {
        /** @example "any" */
        clientOrderID?: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/cb/with`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name PostFiat
     * @request POST:/fiat/cb
     */
    postFiat: (
      body: {
        /** @example "any" */
        clientOrderID?: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/cb`,
        method: "POST",
        body: body,
        ...params
      }),

    /**
     * No description
     *
     * @name CbRepay
     * @request POST:/fiat/cb/repay
     */
    cbRepay: (
      body: {
        /** @example "any" */
        order_id?: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/fiat/cb/repay`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      })
  };
  slots = {
    /**
     * No description
     *
     * @name Lobby
     * @request GET:/slots/lobby/{uuid}/{curr}
     */
    lobby: (uuid: string, curr: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/slots/lobby/${uuid}/${curr}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Details
     * @request GET:/slots/details/{uuid}
     */
    details: (uuid: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/slots/details/${uuid}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name List
     * @request GET:/slots/list
     */
    list: (
      query: {
        /** Create query */
        params: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/slots/list`,
        method: "GET",
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @name ListSearch
     * @request GET:/slots/list/search
     */
    listSearch: (
      query: {
        search?: string;
        /** Create query */
        params: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/slots/list/search`,
        method: "GET",
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @name FavsUser
     * @request GET:/slots/favs/user
     */
    favsUser: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/slots/favs/user`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name ListProviders
     * @request GET:/slots/list/providers
     */
    listProviders: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/slots/list/providers`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name ListDemo
     * @request GET:/slots/list/demo
     */
    listDemo: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/slots/list/demo`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name Demo
     * @request GET:/slots/demo/{uuid}
     */
    demo: (uuid: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/slots/demo/${uuid}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name InitSlot
     * @request POST:/slots/init/slot
     */
    initSlot: (
      body: {
        /** @example "any" */
        game_uuid?: any;
        /** @example "any" */
        curr?: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/slots/init/slot`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name LobbyGames
     * @request GET:/slots/lobby/{uuid}/{curr}/games
     */
    lobbyGames: (uuid: string, curr: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/slots/lobby/${uuid}/${curr}/games`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name LobbyGamesPageExpandTagsParameters
     * @request GET:/slots/lobby/{uuid}/{curr}/games?page=${i}&expand=tags,parameters
     */
    lobbyGamesPageExpandTagsParameters: (
      uuid: string,
      curr: string,
      i: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/slots/lobby/${uuid}/${curr}/games?page=$${i}&expand=tags,parameters`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @name LobbySelfValidate
     * @request POST:/slots/lobby/{uuid}/{curr}/self-validate
     */
    lobbySelfValidate: (
      uuid: string,
      curr: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/slots/lobby/${uuid}/${curr}/self-validate`,
        method: "POST",
        ...params
      })
  };
  slotscb = {
    /**
     * No description
     *
     * @name Slotscb
     * @request POST:/slotscb/
     */
    slotscb: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/slotscb/`,
        method: "POST",
        ...params
      })
  };
  lf = {
    /**
     * No description
     *
     * @name Likefav
     * @request POST:/lf/likefav
     */
    likefav: (
      body: {
        /** @example "0" */
        game?: string;
        /** @example false */
        isLike?: boolean;
        /** @example false */
        isFav?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/lf/likefav`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name SlotLikefav
     * @request POST:/lf/slot/likefav
     */
    slotLikefav: (
      body: {
        /** @example "0" */
        gameId?: string;
        /** @example false */
        isLike?: boolean;
        /** @example false */
        isFav?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/lf/slot/likefav`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name LoadLikes
     * @request POST:/lf/load/likes/{game}
     */
    loadLikes: (game: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/lf/load/likes/${game}`,
        method: "POST",
        ...params
      })
  };
}
